import {React,useContext,useRef,useState} from 'react'
import AuthContext from '../context/AuthContext'
import { jwtDecode } from "jwt-decode"
import {Navigate, useNavigate} from "react-router-dom";
import { Image,Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Modal from "react-bootstrap/Modal"
import logo from '../assets/logo.png'

function ChangePassword() {

    const {update} = useContext(AuthContext)
    const token = localStorage.getItem("authTokens")
    const myref = useRef()
    const swal = require('sweetalert2')
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [new_password, setNew_password] = useState('')
    const [confirm_password, setConfirm_password] = useState('')
    const [show, setShow] = useState(false);
    const open=()=> setShow(true)
    const close =()=> setShow(false)

    
  
    if (token){
      const decode = jwtDecode(token)
      var username = decode.user_id
    }

    const handleSubmit = e => {
      e.preventDefault()
      if (email && new_password.length>=8){
      update(email, new_password,confirm_password)
      }

      else{
        swal.fire({
          title: "Password must be more than 8 characters",
          icon: "error",
          toast: true,
          timer: 6000,
          position: 'top-right',
          timerProgressBar: false,
          showConfirmButton: false,
      })

      }

   
        
 

    }

     
  return (
    <div>
    <>
<section  style={{ backgroundColor: "#110451", paddingTop:"5px", }}>
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col col-xl-10">
        <div className="card" style={{ borderRadius: "1rem",height:'45rem',width:'100%',marginLeft:'30px' }}>
          <div className="row g-0">
            <div className="col-md-6 col-lg-5 d-none d-md-block">
              <img
                src="static/assets/img/home.png"
                alt="login form"
                className="img-fluid"
                style={{ borderRadius: "1rem 0 0 1rem",height:"42rem", width:"100%", paddingBottom:'20%' }}
              />
            </div>
            <div className="col-md-6 col-lg-7 d-flex align-items-center">
              <div className="card-body p-4 p-lg-5 text-black">
                <form onSubmit={handleSubmit}  style={{fontWeight:'bold'}}>
                  
                    <div className="d-flex align-items-center mb-3 pb-1"  style={{backgroundColor:"#110451"}}>

                    <span className="h2 fw-bold mb-0"> <Image src={logo} style={{height:"10mm"}}/> </span>
                    
                  </div>
                  <h5
                
                    style={{ letterSpacing: 1 , fontWeight:'bold', padding:'10px'}}

                  >
                    Reset Password
                  </h5>
                  <label className="form-label" htmlFor="form2Example27">
                      Registered Email
                    </label>
                  <div className="form-outline mb-4">
                    <input
                      type="Email"
                      id="form2Example27"
                      className="form-control form-control-lg"
                      name='Email'
                      onChange={e => setEmail(e.target.value)}
                    />
                    </div>

                  <label className="form-label" htmlFor="form2Example27">
                      New Password
                    </label>
                  <div className="form-outline mb-4">
                    <input
                      type="password"
                      id="form2Example27"
                      className="form-control form-control-lg"
                      name='password'
                      onChange={e => setNew_password(e.target.value)}
                    />
                   
                  </div>
                  <label className="form-label" htmlFor="form2Example27">
                      Confirm Password
                    </label>
                
                  <div className="form-outline mb-4">
                    <input
                      type="password"
                      id="form2Example27"
                      className="form-control form-control-lg"
                      name='password2'
                      onChange={e => setConfirm_password(e.target.value)}
                    />
                  </div>

               
                  <span style={{color:"GrayText",fontSize:"smaller"}}>
                  Your password can’t be too similar to your other personal information.
                  <br/>
                  Your password must contain at least 8 characters.
                  <br/>
                  Your password can’t be a commonly used password
                  <br/>
                  Your password can’t be entirely numeric.
                  <br/>
                  </span>
                  <div className="pt-1 mb-4">
                  <br/>
                  <Button   onClick={open} style={{backgroundColor:"#110451",width:'100%'}}  >
                        Update Password
                       
                      </Button>
                      <Modal show={show}>
                <Modal.Header style={{fontWeight:"bold", fontSize:'5mm', backgroundColor:"#110451",color:'white'}}>Note!!</Modal.Header>
                <Modal.Body style={{fontweight:'bold'}}>Your Password will Reset within 24 hours</Modal.Body>
                <Modal.Footer>
                <Button style={{backgroundColor:'#110451'}} onClick={close} >Close</Button>
               <Button style={{backgroundColor:'#110451'}} onClick={handleSubmit}  type="submit">Confirm</Button>
                </Modal.Footer>


            </Modal>
                
                  </div>
                  <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                      <Link to="/profile" style={{ color: "#393f81",textDecoration:'underline',fontWeight:'bold' }}>
                        Go Back
                      </Link>
                    </p>
                
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  <div
    className="text-center p-3"
    style={{  backgroundColor: "#110451", color:"white"}}
  >
    © Bluvill
  </div>

</>

  </div>

   
  )
}


export default ChangePassword
