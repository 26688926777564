import React , {useContext,useState}from "react";
import { jwtDecode } from "jwt-decode";
import { CDBNavbar, CDBInput, CDBContainer,CDBNavItem,CD} from "cdbreact";
import { Image, NavbarBrand,Nav,NavLink} from "react-bootstrap";
import NavbarToggle from "react-bootstrap/NavbarToggle"
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import logo from "../assets/a-sm.jpg"
import Container from "react-bootstrap/Container"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSignOut,faCircleUser,faTable,faChartLine,faCoins, faUser, faWallet} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../context/AuthContext";
import "./Navbar.css"
import Connect from "./Connect";


const Navbar = (props) => {
  const {user, logoutUser} = useContext(AuthContext)
  const token = localStorage.getItem("authTokens")
  const [show, setShow] = useState(false)
  

  if (token){
    const decoded = jwtDecode(token) 
    var user_id = decoded.user_id
  }
const Onhandle=()=>{
  setShow(true)
}
const OnClose=()=>{
  setShow(false)
}

	return (

        
          <CDBNavbar  className="navbar" expand='lg' >
            <Container>
            <NavbarBrand className="navbarbrand"><span className="navicon" style={{fontWeight:"bold", fontFamily:'Helvetica'}}>Hi {props.username}!</span></NavbarBrand>
            <NavbarToggle  aria-setsize="small" style={{color:"transparent", backgroundColor:"white"}} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
             aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" />
            <NavbarCollapse id="navbarSupportedContent"  >
            <Nav style={{color:'black',paddingLeft:'20px'}} className="mynav">
            <Link to="/dashboard"><FontAwesomeIcon icon={faHome} size="small"/></Link>
            <Link to="/deposit"><FontAwesomeIcon icon={faTable} size="small"/></Link>
            <Link to="/invest"><FontAwesomeIcon icon={faChartLine} size="small"/></Link>
            <Link to="/withdraw"><FontAwesomeIcon icon={faCoins} size="small"/></Link>
            <NavLink ><FontAwesomeIcon icon={faSignOut} size="small" onClick={logoutUser}/></NavLink>
            </Nav >
            </NavbarCollapse>

            
            </Container>
            <FontAwesomeIcon icon={faWallet} size="small" className="navicon"onClick={Onhandle}/>
            <Connect show = {show} onClose={OnClose} />
            <FontAwesomeIcon icon={faSignOut} size="small" className="navicon"onClick={logoutUser}/>
         
           
            
          
          </CDBNavbar>
        
	);
}

export default Navbar;
