import {Line,} from "react-chartjs-2"
import{Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    scales,
    Colors,

} from 'chart.js/auto'
import { jwtDecode } from "jwt-decode"
import { useContext,useState,useEffect} from "react"
import useAxios from "../utils/useAxios"
ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,


)


const Line_Chart=()=>{
    const api = useAxios();
    const token = localStorage.getItem("authTokens")
    const [res, setRes] = useState("")
    const [overview, setOverview] = useState("[]")
    useEffect(() => {
        const fetchData = async () => {
          try{
            const response = await api.get("/overview/")
            setRes(response.data.response)
            setOverview(response.data)
            
            
          } catch (error) {
            console.log(error);
            setRes("Something went wrong")
          }
        }
        fetchData()
      }, [])
      console.log(overview[0].jan)

      var standard = overview[0].standard
      var professional = overview[0].professional
      var premium = overview[0].premium
  

    const data ={
        labels:['Premium', 'Standard', 'Professional'],

        datasets:[{
            label:'Top Invested Plans',
            data:[premium,standard,professional],
            fill:'true',
            
            borderColor:"#110451"
          
            
            
    }]};
    
    const options = {
        
    
        
     
      }
      return(

        <Line data={data} options={options} />
    )
    

        
    }
  





export default Line_Chart;