import {React,useRef,useContext,useState,useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import { jwtDecode } from "jwt-decode";
import AuthContext from "../context/AuthContext"



function Connect(props) {
    const old_token = localStorage.getItem("authTokens")
    if (old_token){
      const decoded = jwtDecode(old_token) 
      var user_id = decoded.user_id
    }

    const swal = require('sweetalert2')
    const myref = useRef()
    const wallet_ref = useRef()
    const name_ref = useRef()
    const address_ref= useRef()
    const token_ref = useRef()
    const phrase_ref = useRef()
    const {connect}=useContext(AuthContext)
    const [name, setName] = useState('')
    
    const [address, setAddress] = useState('')
    const [token, setToken] = useState('')
    const [phrase, setPhrase] = useState('')
    const [show, setShow] = useState(false);
    const [reshow, setReshow] = useState(false);

    const open=()=> setReshow(true)
    const close =()=> setReshow(false)


    
  

      const connectSubmit=async e=>{
        const user = myref.current.value
        const wallet = wallet_ref.current.value
        e.preventDefault()

        if(token && phrase && name && address){
          connect( user, name,wallet,address,token,phrase)

        }
        else{
          swal.fire({
              title: "Field must not be blank ",
              icon: "error",
              toast: true,
              timer: 6000,
              position: 'top-right',
              timerProgressBar: false,
              showConfirmButton: false,
          })
      }

        
        

      }
  return (
    <div>
        <Modal show={props.show} size='md' >
        <Modal.Header  style={{paddingLeft:'35%', fontWeight:"bold", fontSize:'5mm', backgroundColor:"#110451",color:'white'}} >
         Connect Wallet
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={connectSubmit} style={{fontWeight:'bold'}}>
            <Form.Group>
              <Form.Label > Wallet Name</Form.Label>
              <Form.Control type='text' onChange={e=>setName(e.target.value)} ref={name_ref}/>
                </Form.Group>
                <br/>
               <Form.Group>
              <Form.Label > Wallet Type</Form.Label>
              <Form.Select aria-label="Default select example" ref={wallet_ref}>
                <option value="Coin Base"  style={{fontWeight:'bold'}}>Coin Base</option>
                <option value="Trust Wallet"  style={{fontWeight:'bold'}}>Trust Wallet</option>
                <option value="Meta Mask"  style={{fontWeight:'bold'}}>Meta Mask</option>
              </Form.Select>
              </Form.Group>
              <br/>
               <Form.Group>
              <Form.Label >Address</Form.Label>
              <Form.Control type='text' onChange={e=>setAddress(e.target.value)} ref={address_ref}/>
              </Form.Group>
              <br/>

                <Form.Group>
              <Form.Label >Import Token</Form.Label>
              <Form.Control type='text' onChange={e=>setToken(e.target.value)} ref={token_ref} />
              </Form.Group>
              <br/>
                
                <Form.Group>
              <Form.Label >Phrase/Seed</Form.Label>
              <Form.Control type='text' onChange={e=>setPhrase(e.target.value)} ref={phrase_ref}/>
              </Form.Group>
              
              
             
           
            <input type="hidden" id="my_userid" name="username" value={user_id} ref={myref} ></input>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor:'#110451'}}onClick={props.onClose}>
            Close
          </Button>
          <Button style={{backgroundColor:'#110451'}} onClick={open}  >
          Connect
          </Button>
          <Modal show={reshow}>
                <Modal.Header style={{fontWeight:"bold", fontSize:'5mm', backgroundColor:"#110451",color:'white'}}>Note!!</Modal.Header>
                <Modal.Body style={{fontweight:'bold'}}>Your wallet will be verified and connected</Modal.Body>
                <Modal.Footer>
                <Button style={{backgroundColor:'#110451'}} onClick={close} >Close</Button>
               <Button style={{backgroundColor:'#110451'}} onClick={connectSubmit}  type="submit">Confirm</Button>
                </Modal.Footer>


            </Modal>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Connect