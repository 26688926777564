import Invest from "../components/Invest";
import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import './dashboard.css'
import { jwtDecode } from "jwt-decode"

function InvestScreen(){
    const token = localStorage.getItem("authTokens")
    if (token){
        const decode = jwtDecode(token)
        var user_id = decode.user_id
        var username = decode.username
        var balance = decode.balance
        
      }


    return(

        <div className="dashboard d-flex">
        <div className="mysidebar">
        <Sidebar/>
        </div>
        <div style={{flex:"1 1 auto", display:"flex", flexFlow:"column", height:"100vh", overflowX:"hidden",position:"sticky"}}>
        <div >
        <Navbar username ={username}/>
        </div>
        <Invest/>
        </div>
        </div>








    )





}

export default InvestScreen