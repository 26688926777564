import { Card, Container,Row,Col,Button } from "react-bootstrap";
import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import Image from 'react-bootstrap/Image';
import logo from "../assets/a-sm.jpg"
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { jwtDecode } from "jwt-decode"
import useAxios from "../utils/useAxios"
import { useState,useEffect } from "react";
import { Link } from 'react-router-dom'
import './dashboard.css'

function WalletLog(){
    const now=40;
    const percentage = 66;
    const token = localStorage.getItem("authTokens")
    if (token){
      const decode = jwtDecode(token)
      var username = decode.username
      var user_id = decode.user_id
    }
    
    const [res, setRes] = useState("")
    const [table_row, settable_row] = useState("[]")
    const api = useAxios();
    

    useEffect(() => {
      const fetchData = async () => {
        try{
          const response = await api.get(`wallet_log/${user_id}`)
          setRes(response.data.response)
          const data = response.data
          settable_row(data)
        } catch (error) {
          console.log(error);
          setRes("Something went wrong")
          
         
        }
       
      }
      fetchData()
    }, [])

    
    




  
    const table_head=["#","Wallet Name", "Wallet Type", "Status","Period"] 
    const table_body = []
    
   
 for ( let index = 0; index < table_row.length;  index++) {
      

   
  let new_table_body = <tr>
    <td  style={{fontWeight:"bold"}}>{table_row.length-index}</td>
    <td  style={{fontWeight:"bold"}}>{table_row[index].name}</td>
    <td  style={{fontWeight:"bold"}}>{table_row[index].wallet}</td>
    <td  style={{fontWeight:"bold"}}>{table_row[index].status}</td>
    <td  style={{fontWeight:"bold"}}>{table_row[index].time}</td>
    </tr>
   table_body.splice(0,0,new_table_body) 
   console.log(table_row[index].date)
  
 }

    return(
        <div className="dashboard d-flex">
        <div className="mysidebar">
        <Sidebar/>
        </div>
        <div style={{flex:"1 1 auto", display:"flex", flexFlow:"column", height:"100vh", overflowX:"hidden"}}>
        <div>
        <Navbar username={username}/>
        </div>
        <Container style={{backgroundColor:'transparent', height:'auto'}} fluid="sm"> 
            <Card style={{marginTop:'3%', }}>
                <Card.Header style={{fontSize:'large', paddingBottom:'20px',fontWeight:"bold",color:'#110451'}}>Wallet History <br/> <span style={{fontSize:'small', color:'GrayText'}}>Wallet will be connected once it has been verified</span> </Card.Header>

               
            <Table responsive="sm">
      <thead style={{paddingTop:'30px'}}>
        <tr >
          {table_head.map((head,headID)=>(<th className="table-head-font" style={{backgroundColor:'#110451',color:'white'}} key={headID}>{head}</th>))}
          
          
        </tr>
      </thead>
      <tbody style={{fontSize:'small'}}>
       
      {table_body}
       
      </tbody>
    </Table>
            </Card>
        </Container>
        
  
        </div>
        </div>
        
    )
}


export default WalletLog 