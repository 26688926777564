import { CDBSidebar, CDBSidebarContent, CDBSidebarHeader, CDBSidebarMenu, CDBSidebarMenuItem,CDBNavbarNav, CDBIcon, CDBContainer} from 'cdbreact'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Outlet,
    NavLink
    
  } from "react-router-dom";
  import logo from '../assets/logo.png'
  import { Image } from 'react-bootstrap';
  import Dashboard from '../screens/Dashboard';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faHome,faUser,faCoins,faMoneyBillTransfer,faMoneyBills,faToggleOn, faSignOut,faBars } from "@fortawesome/free-solid-svg-icons";
  import './sidebar.css'


  import { NavDropdown ,} from 'react-bootstrap';



const Sidebar=()=>{
    
    
    return(
        
        <div style={{ display: "flex", height: "100%", overflow:"scroll initial", maxWidth:'100%'}} >
        <CDBSidebar  >
           <Link to="/dashboard" style={{color:"white"}}> <CDBSidebarHeader  style={{backgroundColor:'#110451 ', border:'none'}}> <header><Image src={logo} style={{height:"13mm", marginBottom:'10px'}}/></header></CDBSidebarHeader></Link>
            <CDBSidebarContent className="mysidebar"  >
            
            
                    <div>
                    <br/>
                    
                    <ul>
                         <NavLink className="nav-bar-link" to="/dashboard"><CDBSidebarMenuItem icon="home"><span>Dashboard</span></CDBSidebarMenuItem></NavLink>
                        <NavLink className="nav-bar-link" to="/deposit"><CDBSidebarMenuItem icon='table'><span>Deposit</span></CDBSidebarMenuItem></NavLink>
                        <NavLink className="nav-bar-link" to="/invest"><CDBSidebarMenuItem icon='chart-line'> <span>Invest</span></CDBSidebarMenuItem></NavLink>
                        <NavLink className="nav-bar-link" to="/invest-log"><CDBSidebarMenuItem icon='chart-line'> <span>Investment Log</span></CDBSidebarMenuItem></NavLink>
                        <NavLink className="nav-bar-link" to="/withdraw"><CDBSidebarMenuItem icon='coins'><span>Withdraw</span></CDBSidebarMenuItem></NavLink>
                        <NavLink className="nav-bar-link" to="/wallet-log"><CDBSidebarMenuItem icon='wallet'> <span>Wallet</span></CDBSidebarMenuItem></NavLink>
                        <NavLink className="nav-bar-link" to="/profile"><CDBSidebarMenuItem icon='user'> <span>Profile</span></CDBSidebarMenuItem></NavLink>
                        <NavLink className="nav-bar-link" to="/transactions"><CDBSidebarMenuItem icon='chart-bar'> <span>Transactions</span></CDBSidebarMenuItem></NavLink>
                    

                    
                        </ul>
                        </div>
            
                    
                    <Outlet/>
                   
            


            </CDBSidebarContent>


       
        </CDBSidebar>
        </div>

    )




} 
export default Sidebar;