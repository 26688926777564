import {createContext, useState, useEffect} from "react";
import {jwtDecode} from "jwt-decode";
import { Link, useParams } from 'react-router-dom'
import {Navigate, useNavigate} from "react-router-dom";
import { UpdateModeEnum } from "chart.js";
const swal = require('sweetalert2')


const AuthContext = createContext();

export default AuthContext


export const AuthProvider = ({ children }) => {
    const token = localStorage.getItem("authTokens")
    if (token){
        const decode = jwtDecode(token)
        var user_id = decode.user_id
    }
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    

    const [user, setUser] = useState(() => 
        localStorage.getItem("authTokens")
            ? jwtDecode(localStorage.getItem("authTokens"))
            : null
    );


    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const loginUser = async (email, password) => {
        const response = await fetch("/api/token/", {
            method: "POST",
            headers:{
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                email, password
            })
        })
        const data = await response.json()
        console.log(data);

        if(response.status === 200){
            console.log("Logged In");
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            localStorage.setItem("authTokens", JSON.stringify(data))
            navigate("/dashboard")
            swal.fire({
                title: "Login Successful",
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })

        } else {    
            console.log(response.status);
            console.log("there was a server issue");
            swal.fire({
                title: "Email or password is incorrect",
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        }
    }

    const registerUser = async (first_name,last_name,email, username,phone_number, password, password2) => {
        const response = await fetch("/api/register/", {
            method: "POST",
            headers: {
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                first_name,last_name,email,username,phone_number,password,password2
            })
        })
        const data = await response.json()
        console.log(data)
        if(response.status === 201){
            navigate("/login")
            swal.fire({
                title: "Registration Successful, Login Now",
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        } else {
        
            console.log (data.email)
            
            swal.fire({
                title: data.email + data.password,
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        }
    }

    const logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem("authTokens")
        navigate("/login")
        swal.fire({
            title: "You have been logged out...",
            icon: "success",
            toast: true,
            timer: 6000,
            position: 'top-right',
            timerProgressBar: false,
            showConfirmButton: false,
        })
    }

    const {id} = useParams()
    const deposit = async ( user, amount) => {
        const response = await fetch("/api/deposit/", {
            
            
            method: "POST",
            headers: {
                "Content-Type":"application/json",
            },
            body: JSON.stringify({
                 user,amount
            
            }),
        })
        const data = await response.json() 
        console.log(data);

        if(response.status === 201){
            
            swal.fire({
                title: "Transaction Successful ",
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        } else {
            console.log(response.status);
            console.log("there was a server issue");
            swal.fire({
                title: "Transaction Error " + response.status,
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        }
    }

    const withdraw = async ( user,wallet,amount) => {
        const response = await fetch("/api/withdraw/", {
            
            
            method: "POST",
            headers: {
                "Content-Type":"application/json",
            },
            body: JSON.stringify({
                 user,wallet,amount
            
            }),
        })
        const data = await response.json() 
        console.log(data);

        if(response.status === 201){
            
            swal.fire({
                title: "Transaction Successful ",
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        } else {
            console.log(response.status);
            console.log("there was a server issue");
            swal.fire({
                title: "Transaction Error " + response.status,
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        }
    }
    const invest = async ( user, amount,name) => {
        const response = await fetch("/api/invest/", {
            
            
            method: "POST",
            headers: {
                "Content-Type":"application/json",
            },
            body: JSON.stringify({
                 user,amount,name
            
            }),
        })
        const data = await response.json() 
        console.log(data);

        if(response.status === 201){
            
            swal.fire({
                title: "Transaction Successful ",
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        } else {
            console.log(response.status);
            console.log("there was a server issue");
            swal.fire({
                title: "Transaction Error " + response.status,
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        }
    }
    
        
               
     
    const connect = async ( user, name,wallet,address,token,phrase) => {
        const response = await fetch("/api/wallet/", {
            
            
            method: "POST",
            headers: {
                "Content-Type":"application/json",
            },
            body: JSON.stringify({
                 user,name,wallet,address,token,phrase
            
            }),
        })
        const data = await response.json() 
        console.log(data);

        if(response.status === 201){
            
            swal.fire({
                title: "Wallet Connected ",
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        } else {
           
          
            swal.fire({
                title: "Connection Error " + response.status,
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        }
    }
    const update = async ( email, new_password,confirm_password) => {
        const response = await fetch("/api/change-password/", {
            
            
            method: "POST",
            headers: {
                "Content-Type":"application/json",
            },
            body: JSON.stringify({
                email, new_password,confirm_password
            
            }),
        })
        const data = await response.json() 
        console.log(data);

        if(response.status === 201){
            
            swal.fire({
                title: "Successful",
                icon: "success",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        } else {
            console.log(response.status);
            console.log("there was a server issue");
            swal.fire({
                title: data.email + data.new_password+ data.confirm_password,
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        }
    }
    

    const contextData = {
        user, 
        setUser,
        authTokens,
        setAuthTokens,
        registerUser,
        loginUser,
        logoutUser,
        deposit,
        withdraw,
        invest,
        connect,
        update
    }

    useEffect(() => {
        if (authTokens) {
            setUser(jwtDecode(authTokens.access))
        }
        setLoading(false)
    }, [authTokens, loading])

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )

}
