import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import './invest.css'
import Button from 'react-bootstrap/esm/Button'
import {} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, fa2 } from '@fortawesome/free-solid-svg-icons'
import  Modal  from "react-bootstrap/Modal";
import {Form,FormGroup} from 'react-bootstrap'
import { jwtDecode } from "jwt-decode"
import {useState,useRef,useContext} from 'react'
import AuthContext from "../context/AuthContext"

function Invest(){
    const swal = require('sweetalert2')
    const token = localStorage.getItem("authTokens")
    const myref = useRef()
    const standardRef=useRef()
    const stanName = useRef()
    const professionalRef=useRef()
    const proName = useRef()
    const premiumRef=useRef()
    const preName = useRef()
    const {invest} = useContext(AuthContext)
    const [validated, setValidated] = useState(false);


    
    
  
    if (token){
      const decode = jwtDecode(token)
      var user_id = decode.user_id
      var username = decode.username
      var balance = decode.balance
      
    }

    const [stshow, setStshow] = useState(false);
    const [preshow, setPreshow] = useState(false);
    const [proshow, setProshow] = useState(false);
   
    const [amount, setAmount] = useState(false);

    const standardSubmit = async e  => {
        e.preventDefault()
        const form = standardRef.current.value;
        const user = myref.current.value
        const name = stanName.current.value
        if (form>=500 && form<=5000 && balance>=amount){
           
   
        invest( user,amount,name)
        
         
        }
        else if(balance<amount){
            swal.fire({
              title: "Balance is too low",
              icon: "error",
              toast: true,
              timer: 6000,
              position: 'top-right',
              timerProgressBar: false,
              showConfirmButton: false,
          })
        
          }
        else{
            console.log('invalid')
            swal.fire({
                title: "Invalid Amount",
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        }

        
    
    ;
        
      }

      const premiumSubmit = async e  => {
        e.preventDefault()
        const form = premiumRef.current.value;
        const user = myref.current.value
        const name = preName.current.value
        if (form>=50000  && balance>=amount){
      
        invest( user,amount,name)
        
         
        }
        else if(balance<amount){
            swal.fire({
              title: "Balance is too low",
              icon: "error",
              toast: true,
              timer: 6000,
              position: 'top-right',
              timerProgressBar: false,
              showConfirmButton: false,
          })
        
          }
        else{
            console.log('invalid')
            swal.fire({
                title: "Invalid Amount",
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: false,
                showConfirmButton: false,
            })
        }
   
      }

      const ProfessionalSubmit = async e  => {
        e.preventDefault()
        const form = professionalRef.current.value;
        const user = myref.current.value
        const name = proName.current.value
        if (form>=6000 && form<=50000 && balance>=amount){
       
       
        invest( user,amount,name)
        
         
        }
        else if(balance<amount){
            swal.fire({
              title: "Balance is too low",
              icon: "error",
              toast: true,
              timer: 6000,
              position: 'top-right',
              timerProgressBar: true,
              showConfirmButton: false,
          })
        
          }
        else{
            console.log('invalid')
            swal.fire({
                title: "Invalid Amount",
                icon: "error",
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
       
      }
    

    


    const Sthandle =()=> setStshow(true);
    const Prehandle =()=> setPreshow(true);
    const Prohandle =()=> setProshow(true);
    const Stclose =()=> setStshow(false);
    const Preclose =()=> setPreshow(false);
    const Proclose=()=> setProshow(false);
 
    return(

        <Container   style={{paddingLeft:'30px', paddingTop:'20px',  height:"100%"}}>
            <h1 className='invest-container'>Our Investment Plans</h1>
            
            <Row>
                <Col>
                <Card className='invest-plans'  style={{  background:"linear-gradient(90deg,#110451 30%, #0099ff 100%)", }}>
                    <Card.Title style={{fontSize:'5mm', fontWeight:'bold', color:"white"}}>Standard Plan</Card.Title>
                    <Card.Body style={{fontWeight:'bold', color:"white"}}>
                    <Card.Title className='invest-title'>2.0%</Card.Title>
                    <span style={{fontSize:'5mm', fontFamily:"monospace"}}>Daily</span>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Total</Col>
                        <Col style={{paddingRight:'17%'}}>60%</Col>
                    </Row>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Duration</Col>
                        <Col style={{paddingRight:'17%'}}>30 days</Col>
                    </Row>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Minimium</Col>
                        <Col style={{paddingRight:'17%'}}>$500</Col>
                    </Row>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Maximum</Col>
                        <Col style={{paddingRight:'17%'}}>$5,000</Col>
                    </Row>
                    <Button className="my-button"  onClick={Sthandle}>Choose this plan</Button>
                    </Card.Body>
                    
        
                   <Modal show={stshow} size="md" backdrop={true} >
                  <Modal.Header style={{paddingLeft:'35%',fontWeight:"bold", fontSize:'6mm',backgroundColor:'#110451',color:"white"}} >Standard Plan</Modal.Header>
                 <Modal.Body>
                    <Form onSubmit={standardSubmit} noValidate validated={validated}>
                        <FormGroup>
                        <Form.Label style={{paddingRight:'80%',}}> Amount</Form.Label>
                        <Form.Control type='number' placeholder='0.00' onChange={e=>setAmount(e.target.value)} max="10000" min="800" ref={standardRef}  />
                        <Form.Text style={{fontFamily:"monospace", color:"GrayText"}}>Min:$500 Max:$5,000</Form.Text>
                        </FormGroup>
                        <input type="hidden" id="my_userid" name="username" value={user_id} ref={myref} ></input>
                        <input type="hidden" id="name_id" name="name" value="Standard" ref={stanName}></input>
                        
                    </Form>

                </Modal.Body>
               <Modal.Footer>
               <Button style={{backgroundColor:'#110451'}}  onClick={Stclose} >Close</Button>
               <Button style={{backgroundColor:'#110451'}} onClick={standardSubmit}>Confirm</Button>
               </Modal.Footer>
              </Modal>

        

            </Card>
                
            </Col>

            <Col>
                <Card className='invest-plans'   style={{ background:'linear-gradient(90deg,#ff6600 0%, #ffcc99 100%)'}}>
                    <Card.Title style={{fontSize:'5mm', fontWeight:'bold', color:"white"}}>Professional Plan</Card.Title>
                   <Card.Body style={{fontWeight:'bold', color:"white"}}>
                    <Card.Title className='invest-title'>3.5%</Card.Title>
                    <span style={{fontSize:'5mm', fontFamily:"monospace"}}>Daily</span>

                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Total</Col>
                        <Col style={{paddingRight:'17%'}}>49%</Col>
                    </Row>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Duration</Col>
                        <Col style={{paddingRight:'17%'}}>14 days</Col>
                    </Row>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Minimium</Col>
                        <Col style={{paddingRight:'17%'}}>$6,000</Col>
                    </Row>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Maximum</Col>
                        <Col style={{paddingRight:'17%'}}>$50,000</Col>
                    </Row>
                    

                     
                    <Button  className="my-button" onClick={Prohandle}>Choose this plan</Button>
                    </Card.Body>
        
                   <Modal show={proshow} size="md" backdrop={true} >
                   <Modal.Header style={{paddingLeft:'35%',fontWeight:"bold", fontSize:'6mm',backgroundColor:'#110451',color:"white"}} >Professional Plan</Modal.Header>

                   <Modal.Body>
                   <Form  onSubmit={ProfessionalSubmit}>
                      <FormGroup>
                        <Form.Label style={{paddingRight:'80%',}}> Amount</Form.Label>
                        <Form.Control type='number' placeholder='0.00'  onChange={e=>setAmount(e.target.value)} ref={professionalRef}/>
                        <Form.Text style={{fontFamily:"monospace", color:"GrayText"}}>Min:$6,000  Max:$50,000</Form.Text>
                        </FormGroup>
                        <input type="hidden" id="my_userid" name="username" value={user_id} ref={myref} ></input>
                        <input type="hidden" id="name_id" name="name" value="Professional" ref={proName} ></input>
                        
                    </Form>

                    </Modal.Body>
                    <Modal.Footer>
                <Button style={{backgroundColor:'#110451'}} onClick={Proclose} >Close</Button>
                <Button style={{backgroundColor:'#110451'}} onClick={ProfessionalSubmit}>Confirm</Button>
                </Modal.Footer>
                </Modal>

                </Card>
                
                </Col>
                <Col>
                <Card  className='invest-plans'    style={{background:"linear-gradient(90deg,#990066 30%, #ff66ff 100%)",}}>
                    <Card.Title style={{fontSize:'5mm', fontWeight:'bold', color:"white"}}>Premium Plan</Card.Title>
                   <Card.Body style={{fontWeight:'bold', color:"white"}}>
                    <Card.Title className='invest-title'>7.0%</Card.Title>
                    <span style={{fontSize:'5mm', fontFamily:'monospace'}}>Daily</span>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Total</Col>
                        <Col style={{paddingRight:'17%'}}>49%</Col>
                    </Row>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Duration</Col>
                        <Col style={{paddingRight:'17%'}}>7 days</Col>
                    </Row>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Minimium</Col>
                        <Col style={{paddingRight:'17%'}}>$51,000</Col>
                    </Row>
                    <Row style={{paddingTop:'7%', textAlign:'center', paddingLeft:"23px"}} >
                        <Col style={{paddingRight:'17%'}}>Maximum</Col>
                        <Col style={{paddingRight:'17%'}}>Unlimited</Col>
                    </Row>
                    <Button  className="my-button" onClick={Prehandle}>Choose this plan</Button>
                    </Card.Body>


                
                    
                     <Modal show={preshow} size="md" backdrop={true} >
                    <Modal.Header style={{paddingLeft:'35%',fontWeight:"bold", fontSize:'6mm',backgroundColor:'#110451',color:"white"}} >Premium Plan</Modal.Header>
                   
                      <Modal.Body>
                         <Form  onSubmit={premiumSubmit}>
                       <FormGroup>
                       <Form.Label style={{paddingRight:'80%',}}> Amount</Form.Label>
                       <Form.Control type='number' placeholder='0.00' onChange={e=>setAmount(e.target.value)} ref={premiumRef}/>
                        <Form.Text style={{fontFamily:"monospace", color:"GrayText"}}>Min:$51,000  Max:Unlimited</Form.Text>
                        </FormGroup>
                        <input type="hidden" id="my_userid" name="username" value={user_id} ref={myref} ></input>
                        <input type="hidden" id="name_is" name="name" value="Premium" ref={preName} ></input>
                    
                     </Form >

                        </Modal.Body>
                        <Modal.Footer>
                    <Button style={{backgroundColor:'#110451'}} onClick={Preclose} >Close</Button>
                    <Button style={{backgroundColor:'#110451'}} onClick={premiumSubmit} >Confirm</Button>
                    </Modal.Footer>
                    </Modal>

                   

                </Card>
                
                </Col>







            </Row>








        </Container>
          
)




}export default Invest