import { Card, Container,Row,Col,Button } from "react-bootstrap";
import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import Form from 'react-bootstrap/Form'
import { jwtDecode } from "jwt-decode"
import { Link } from 'react-router-dom'
import './dashboard.css'

function Profile(){
    const token = localStorage.getItem("authTokens")
    if (token){
        const decode = jwtDecode(token)
        var username = decode.username
        var email = decode.email
        var balance = decode.balance
        var first_name = decode.first_name
        var last_name = decode.last_name
        var phone_number = decode.phone_number
        var current_plan = decode.current_plan
        var total_invest_gain = decode.total_invest_gain
  
      }

    
    let options = { style: 'currency', currency: 'USD' }


    var formattedMoney1 = balance.toLocaleString('en-US', options);
    var formattedMoney5 = total_invest_gain.toLocaleString('en-US', options)


    return(
        <div className="dashboard d-flex">
        <div className="mysidebar">
        <Sidebar/>
        </div>
        <div style={{flex:"1 1 auto", display:"flex", flexFlow:"column", height:"100vh", overflowX:"hidden"}}>
        <div >
        <Navbar username = {username}/>
        </div>
        <Container fluid style={{backgroundColor:'whitesmoke'}}>
           
                <Col>
                <Card  style={{marginRight:'15px', width:'100%', marginTop:'15px', marginBottom:'15px',border:'none',backgroundColor:'whitesmoke'}} >
                 <Card.Header style={{fontSize:'7mm',backgroundColor:'whitesmoke',border:"none",fontWeight:"bold",color:'#110451'}}>Personal Information</Card.Header>
                 
                <Form style={{paddingLeft:'30px', paddingTop:'30px',backgroundColor:'whitesmoke',}}>
                    <Row>
                    <Form.Group as={Col} style={{paddingLeft:'13px',}}>
                    <Form.Label > First Name</Form.Label>
                    <Form.Control style={{backgroundColor:"transparent",fontWeight:'bold',border:'none'}} type='text' placeholder="NIL" value={first_name} readOnly/>
                    </Form.Group>
                    <br/>

                    <Form.Group as={Col} style={{paddingLeft:'13px'}}>
                    <Form.Label >Last Name</Form.Label>
                    <Form.Control style={{backgroundColor:"transparent",fontWeight:'bold',border:'none'}} type='text' placeholder="NIL" value={last_name} readOnly/>
                    </Form.Group>
                    </Row>
                    <br/>
                    
                    <Row>
                    <Form.Group  as={Col} style={{paddingLeft:'13px'}}>
                    <Form.Label >Username</Form.Label>
                    <Form.Control style={{backgroundColor:"transparent",fontWeight:'bold',border:'none'}} type='text' placeholder="NIL" value={username} readOnly/>
                    </Form.Group>
                    <br/>

                    <Form.Group as={Col} style={{paddingLeft:'13px'}}>
                    <Form.Label > Email Address</Form.Label>
                    <Form.Control style={{backgroundColor:"transparent",fontWeight:'bold',border:'none'}} type='text'placeholder="NIL" value={email} readOnly/>
                    </Form.Group>
                    </Row>
                    <br/>

                     <Row>
                    <Form.Group as={Col} style={{paddingLeft:'13px'}}>
                    <Form.Label >Phone Number </Form.Label>
                    <Form.Control style={{backgroundColor:"transparent",fontWeight:'bold',border:'none'}} type='text' placeholder="NIL" value={phone_number} readOnly/>
                    </Form.Group>
                    <br/>
                    <Form.Group as={Col} style={{paddingLeft:'13px'}}>
                    <Form.Label >Current Plan </Form.Label>
                    <Form.Control style={{backgroundColor:"transparent",fontWeight:'bold',border:'none'}} type='text' placeholder="NIL" value={current_plan} readOnly/>
                  
                    </Form.Group>
                    </Row>
                    <br/>
                    <Row>
                    <Form.Group as={Col} style={{paddingLeft:'13px'}}>
                    <Form.Label >Total Balance </Form.Label>
                    <Form.Control style={{backgroundColor:"transparent",fontWeight:'bold',border:'none'}} type='text' placeholder="NIL" value={formattedMoney1} readOnly/>
                    </Form.Group>
                    <br/>
                
                    <Form.Group  as={Col}   style={{paddingLeft:'13px'}}>
                    <Form.Label >Total Profit </Form.Label>
                    <Form.Control style={{backgroundColor:"transparent",fontWeight:'bold',border:'none'}} type='text' placeholder="NIL" value = {formattedMoney5} readOnly/>
                    </Form.Group>
                    </Row>
                    <br/>


                </Form>
                <Card.Footer  style={{backgroundColor:'transparent',border:'none'}}><Link to='/update-password'><Button style={{backgroundColor:'#110451 ', border:'none'}}> Change Password?</Button></Link></Card.Footer>
                
                </Card>
                </Col>
        </Container>
        </div>
        </div>
        

        


    )





    
    



}
export default Profile;