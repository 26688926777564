import React, { useContext,useRef } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import { CDBCard,CDBContainer } from 'cdbreact';
import Form from 'react-bootstrap/Form'
import logo from '../assets/logo.png'
import './login.css'

import { Image,Button,Card } from 'react-bootstrap';




function Loginpage() {

  const {loginUser} = useContext(AuthContext)
  const emailRef= useRef()
  const passwordRef= useRef()
  const swal = require('sweetalert2')
  const handleSubmit = e => {

    e.preventDefault()
    const email = emailRef.current.value
    const password = passwordRef.current.value

    if(email && password){
      loginUser(email, password)

    }
    else{
      swal.fire({
        title: "Fields must not be blank",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: false,
        showConfirmButton: false,
    })

    }

    
   
  }

  return (
    
      <>
  <section  style={{ backgroundColor: "#110451",  }}>
  <Link to="/" >
  <div style={{justifySelf:'center'}}><Image src={logo} />
  <br/>
  <span style={{color:'white',padding:'40px', fontWeight:'bold',fontSize:'large'}}>SIGN IN </span>
  </div>
  </Link>
  
      <Card className='logcard'>
     
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type='email'   name='email' ref={emailRef} />

            </Form.Group>
             <br/>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type='password'  name='password' ref={passwordRef}/>

            </Form.Group>
            <br/>
           <Link  to="/update-password" style={{color:'#110451',textDecoration:'underline'}} > Forgot password?</Link>
            <br/>
            <Button style={{width:'100%', backgroundColor:'#110451'}} type='submit'>Login</Button>
          </Form>

        </Card.Body>
        <Card.Footer>
        <Link to="/register" style={{color:'#110451',textDecoration:'underline'}}>Don't have an account? Register</Link>


        </Card.Footer>
        

      </Card>
   
  


  </section>


</>

   
  )
}

export default Loginpage