import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import './deposit.css'
import * as React from "react"
import InputGroup from 'react-bootstrap/InputGroup'
import btcImage from '../assets/frame.png'
import {CDBCard,CDBCardBody,CDBContainer,} from 'cdbreact'
import {faCopy} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState,useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { jwtDecode } from "jwt-decode"
import useAxios from "../utils/useAxios"



function Deposit(){
 

  const swal = require('sweetalert2')
  const api = useAxios()
  const [btc_address,setBtc_Address]=useState("[]")


  const copy =()=>{
    console.log('copied')
    swal.fire({
      title: "Copied!",
      icon: "success",
      toast: true,
      timer: 6000,
      position: 'top-right',
      timerProgressBar: false,
      showConfirmButton: false,
      
  })
    
  }
  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await api.get("/address/")
        setBtc_Address(response.data)
      
        
      } catch (error) {
        console.log(error);
  
      }
    }
    fetchData()
  }, [])
  var new_address=btc_address[0].address



    return(
      <CDBContainer >
        
        <CDBCard style={{marginTop:'15px',marginRight:'3px', textAlign:'center',maxWidth:'100%',height:'27rem', border:'none',}} >
       
        
          <Card.Title style={{paddingTop:"10px",fontSize:'small'}}>Use the following btc address to deposit funds</Card.Title>
          <Card.Img src={btcImage}  style={{maxWidth:'70%',height:'20rem'}}></Card.Img>
          <Form>
            <Form.Group>
              <Form.Label style={{paddingRight:'70%',paddingTop:'10px'}}> Deposit Address</Form.Label>
              <InputGroup>
              <CopyToClipboard onCopy={copy} text={new_address} >
              <Button style={{backgroundColor:'#110451 ', border:'none'}} >
          <FontAwesomeIcon icon={faCopy} color='white' />
        </Button>
              </CopyToClipboard>
       
              <Form.Control type='text'  value={new_address}/>
              </InputGroup>
            </Form.Group>

        <div className="d-grid gap-2" style={{paddingTop:'43px'}}>
      </div>

       </Form>
  
       
        
       
      </CDBCard>
      </CDBContainer>

        
    )




}
export default Deposit