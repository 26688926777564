import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import Dashboard from './screens/Dashboard'
import DepositScreen from "./screens/DepositScreen";
import InvestScreen from "./screens/InvestScreen";
import WithdrawScreen from "./screens/WithdrawScreen";
import Modals from "./components/Modal";
import Profile from "./screens/Profile";
import Transaction from "./screens/Transactions";
import Login from "./screens/Login";
import Register from "./screens/Register";
import InvestLog from './screens/InvestLog'
import ChangePassword from './screens/ChangePassword';
import Landing from './screens/Landing';
import WalletLog from './screens/WalletLog';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Switch
} from "react-router-dom";

import './App.css'
import { Fragment } from 'react';


function App() {



  return (
    

    <>


    <div className="fill-window">
    
    <AuthProvider>
      <Routes>
        <Route  path="/dashboard" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
        <Route path="/deposit" element={<PrivateRoute><DepositScreen/></PrivateRoute>}/>
        <Route path="/invest" element={<PrivateRoute><InvestScreen/></PrivateRoute>}/>
        <Route path="/withdraw" element={<PrivateRoute><WithdrawScreen/></PrivateRoute>}/>
        <Route path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
        <Route path="/transactions" element={<PrivateRoute><Transaction/></PrivateRoute>}/>
        <Route path="/wallet-log" element={<PrivateRoute><WalletLog/></PrivateRoute>}/>
        <Route path="/invest-log" element={<PrivateRoute><InvestLog/></PrivateRoute>}/>
        <Route path="/register" element={<Register/>} />
        <Route path="/update-password" element={<ChangePassword/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="" element={<Landing/>}/>
        


      </Routes>
      </AuthProvider>
     
    
     </div>
     </>



  )
}
export default App;
