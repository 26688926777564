import Image from "react-bootstrap/Image"
import { Link } from 'react-router-dom'
import {Row,Col,Button, CardImg} from "react-bootstrap"
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/esm/Container"
import { useState,useEffect } from "react"
import logo from "../assets/a-sm.jpg"
import { jwtDecode } from "jwt-decode"
import Navbar from "../components/Navbar"
import Nav from "react-bootstrap/esm/Nav"
import OffCanvas from "react-bootstrap/Offcanvas"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Sidebar from "../components/SideBar"
import { CDBCard, CDBDropDown, CDBDropDownMenu, CDBDropDownToggle, CDBDropDownItem, CDBPane, CDBPaneImage, CDBCardImage, CDBBtn, CDBCardBody, CDBCardTitle, CDBCardText, CDBIcon, CDBContainer } from "cdbreact";

import { faBullseye,faUserCircle, faMoneyBillTrendUp,faWallet,faChartLine,
    faHome,faMoneyBill,faMoneyBillTransfer,faCoins,faRightToBracket,faCircleArrowUp,faUser,faArrowUp,faLineChart, faDollarSign,
    faSignOut,} from '@fortawesome/free-solid-svg-icons'
import './dashboard.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Pie_Chart from "../components/PieChart"
import AuthContext from "../context/AuthContext"
import useAxios from "../utils/useAxios"
import Bar_Chart from "../components/BarChart"
import Linechart from "../components/LineChart"
import Carousel from 'react-bootstrap/Carousel';
import ReactCardSlider from 'react-card-slider-component'
import CardBody from "react-bootstrap/Card"
import CardHeader from "react-bootstrap/esm/CardHeader"
import invest_pic from "../assets/invest_pic.png"
import { useContext } from "react"




function Dashboard(){
  
    const percentage = 66;
    let options = { style: 'currency', currency: 'USD' }
    
    const [res, setRes] = useState("")
    const [data, setData] = useState("[]")
    const api = useAxios();
    const token = localStorage.getItem("authTokens")
    const { logoutUser} = useContext(AuthContext)

    if (token){
      const decode = jwtDecode(token)
      var user_id = decode.user_id
      var username = decode.username
      var full_name = decode.full_name
      var balance = decode.balance
      var current_plan = decode.current_plan
      var total_deposit  = decode.total_deposit
      var total_invest =    decode.total_invest
      var total_withdraw =  decode.total_withdraw
      var total_invest_gain = decode.total_invest_gain

    }
    var formattedMoney1 = balance.toLocaleString('en-US', options);
    var formattedMoney2 =  total_deposit.toLocaleString('en-US', options);
    var formattedMoney3 = total_invest.toLocaleString('en-US', options);
    var formattedMoney4 = total_withdraw.toLocaleString('en-US', options);
    var formattedMoney5 = total_invest_gain.toLocaleString('en-US', options)

    useEffect(() => {
      const fetchData = async () => {
        try{
          const response = await api.get("/overview/")
          setRes(response.data.response)
          setData(response.data)
          console.log(data)
          
        } catch (error) {
          console.log(error);
          setRes("Something went wrong")
        }
      }
      fetchData()
    }, [])
     
   






    return(
        <div className="dashboard d-flex">
        <div className="mysidebar">
        <Sidebar/>
        </div>
        <div className="dashboard-div">
        <div >
        <Navbar  username={username}/>
        </div>
        
        <CDBContainer className="my-container" fluid  >
        <Container fluid >
         
         <div className="mobile-card" >
        
            <h1 style={{paddingBottom:'30px',paddingTop:'8px', paddingRight:"50px",
            marginRight:'50px', fontSize:"6mm", justifyItems:"left",
            textAlign:"left", color:"black",fontWeight:"bold",}}>Hi {username}</h1>
            <Link to="/profile"><FontAwesomeIcon  icon={faUserCircle} color="#110451" size="large"   style={{marginTop:"10px",marginLeft:'60px',marginBottom:"10px", height:'8mm'}}/>
</Link>
      
        </div>
       

       
         <Row sm="1" lg={4} xs="1">
           <Col xs="1" sm="1" md="1">
           <CDBCard className="my-card" style={{border:'none',background:"linear-gradient(90deg,#110451 30%, #0099ff 100%)",paddingTop:'10px'}} >
            <FontAwesomeIcon  icon={faMoneyBillTransfer} size="lg" style={{color:"white",paddingLeft:'85%'}}/>
             <Card.Header style= {{border:'none', paddingTop:'15px',
             background:'white', fontSize:'large',color:"white", fontWeight:"bold",paddingBottom:'3px',fontFamily:'monospace',backgroundColor:"", }}>Total Deposit</Card.Header>
            
             <Card.Title style={{fontSize:'10mm', color:'white', paddingTop:'20px',fontFamily:"Droid Serif", fontWeight:'bold'}}>{formattedMoney2}
             
             </Card.Title>
        
             </CDBCard>
             </Col>

             <Col>
             <CDBCard className="my-card" style={{ background:"linear-gradient(90deg,#ff6600 0%, #ffcc99 100%",border:'none',paddingTop:'10px' }} >
             <FontAwesomeIcon icon={faChartLine} style={{ paddingLeft:'90%',color:'white'}} size="lg" />
             <Card.Header style={{border:'none',paddingTop:'15px',
             background:'white', fontSize:'large',color:"white", fontWeight:"bold",paddingBottom:'3px',fontFamily:'monospace', background:"transparent",}}>Total Investment</Card.Header>
             <Card.Title style={{fontSize:'10mm',  paddingTop:'20px',fontFamily:"Droid Serif", color:"white", fontWeight:"bold"}}>{formattedMoney3}
              
             </Card.Title>
             </CDBCard>
             </Col>
             <Col>
             <CDBCard className="my-card" style={{background:"linear-gradient(90deg,#006600 0%, #00cc00 100%",border:'none',padding:"10px"}} >
            <FontAwesomeIcon icon={faDollarSign} style={{ paddingLeft:'90%',color:'white'}} size="lg"/>
             <Card.Header style={{border:'none',paddingBottom:'3px', fontWeight:'bold',
             background:'white', fontSize:'large',color:'white',paddingTop:'15px',fontFamily:'monospace',background:"transparent",}}>Current Balance</Card.Header>
             <Card.Title style={{fontSize:'10mm', color:'white', paddingTop:'20px',fontFamily:"Droid Serif", fontWeight:'bold'}}>{formattedMoney1}
             
             </Card.Title>
             
             </CDBCard>
             
             </Col>
             <Col>
           <CDBCard  className="my-card-withdraw" style={{background:"linear-gradient(90deg,#0066ff 0%, #0099ff 100%)",border:'none', padding:'10px'}}>
           <FontAwesomeIcon icon={faCoins} style={{ paddingLeft:'90%',color:'white'}} size="lg"/>
             <Card.Header style= {{border:'none',
             background:'transparent', fontSize:'large',color:"white", fontWeight:"bold",paddingBottom:'3px',fontFamily:'monospace',paddingTop:'15px' }}>Total Withdraw</Card.Header>
            
             <Card.Title style={{fontSize:'10mm', color:'white', paddingTop:'20px',fontFamily:"Droid Serif", fontWeight:'bold'}}>{formattedMoney4}
            
             </Card.Title>
        
             </CDBCard>
             </Col>
         </Row >
        
        </Container>
        <div className="mycard-pending"> 
        <h1 style={{paddingBottom:'15px',paddingTop:'8px', paddingRight:"50px",
            marginRight:'50px', fontSize:"6mm", justifyItems:"left",
            textAlign:"left", color:"black",fontWeight:"bold",}}>Recent Transactions</h1>
            <Card    style={{ backgroundColor:'#110451',height:'3rem',width:'85%',boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',borderRadius:"20px"}} >
                <CardHeader style={{color:"white"}}><Link to="/transactions">Transactions </Link></CardHeader>
        
                

            </Card>
            <br/>
            <Card style={{ backgroundColor:'#110451',height:'3rem',width:'85%',boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',borderRadius:"20px"}} >
                <CardHeader style={{color:"white"}}><Link to="/invest-log">Invest </Link> </CardHeader>
        
                

            </Card>
            <br/>
            <Card style={{ backgroundColor:'#110451',height:'3rem',width:'85%',boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',borderRadius:"20px"}} >
            <Card.Header style={{color:"white"}}><Link to="/wallet-log">Wallet</Link></Card.Header>
            
            </Card>
            
        </div>
        
        <div  style={{marginTop:'10px',}}>
        <Row lg='2' sm="1" xs="none">

            <Col >

             <CDBCard className="nocardi"  style={{backgroundColor:'whitesmoke'}} >
                 <CDBCardBody >
              
                        
                        <CardHeader style={{fontWeight:"bold",color:'black',fontSize:"5mm", padding:"15px",borderBottom:'solid 2px gray'}}>Investment Overview</CardHeader>
                        <Card.Title style={{color:"grey", fontSize:"3.6mm", padding:"8px",}} >A glance summary of your investment</Card.Title>
                        <br/>
                        <Row sm='2'>
                          <Col>
                        <CDBCard className="mini-card" style={{border:'none', background:"linear-gradient(90deg,#ff6600 0%, #ffcc99 100%",}} >
                          
                          <Card.Header style= {{border:'none', paddingTop:'15px',
                          background:'white', fontSize:'large',color:"white", fontWeight:"bold",paddingBottom:'3px',fontFamily:'monospace',backgroundColor:"", }}>Amount Invested</Card.Header>
                          
                          <Card.Title style={{fontSize:'9mm', color:'white', paddingTop:'20px',fontFamily:"Droid Serif", fontWeight:'bold'}}>{formattedMoney3}
                          
                          </Card.Title>
        
                        </CDBCard>
                        </Col>
                        
                        <Col>
                        <CDBCard className="mini-card" style={{border:'none',background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(105,99,99,1) 0%)',}} >
                          
                          <Card.Header style= {{border:'none', paddingTop:'15px',
                          background:'white', fontSize:'large',color:"white", fontWeight:"bold",paddingBottom:'3px',fontFamily:'monospace',backgroundColor:"", }}>Total Profit</Card.Header>
                          
                          <Card.Title style={{fontSize:'9mm', color:'white', paddingTop:'20px',fontFamily:"Droid Serif", fontWeight:'bold'}}>{formattedMoney5}
                          
                          </Card.Title>
        
                        </CDBCard>
                        </Col>
                        </Row>
                        <br/>
                     
         
                 
            <Card.Footer><Link to="/invest"><Button className="mini-button">Invest</Button></Link></Card.Footer>
            </CDBCardBody>
          </CDBCard >
          </Col>
         
            <Col>
          <CDBCard className="nocardii"  >
            <CDBCardBody >
    
                    <CardHeader style={{fontWeight:"bold", fontSize:"5mm", padding:"15px",borderBottom:'solid 2px grey'}}>Top Invested Plans</CardHeader>
                        <Card.Title style={{color:"GrayText", fontSize:"3.6mm", padding:"8px"}} >Overview of our top invested plans</Card.Title>
                   <Linechart/>
           

            </CDBCardBody>
            




           
          </CDBCard>
          </Col>
         
        
          </Row>
      </div>
    </CDBContainer>
        </div>
        </div>


  



    )




}
export default Dashboard;