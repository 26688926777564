import {React,useRef} from 'react'
import {
 
  Link,
 
  
} from "react-router-dom";

function Landing() {
  const swal = require('sweetalert2')
  const message_ref = useRef()
  const email_ref = useRef()

 
   const sendSubmit=(e)=>{
    const message = message_ref.current.value
    const email = email_ref.current.value
    e.preventDefault()
    if(message&&email){
      swal.fire({
        title: "Message Sent ",
        icon: "success",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: false,
        showConfirmButton: false,
    })
    }
    else(
      swal.fire({
        title: "Fields must not be blank ",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: false,
        showConfirmButton: false,
    })

    )
   
   }
  return (
    <div>
            <header>
      <nav>
        <a class="logo" href="#"><img src="static/assets/img/logo.png" alt="Logo" draggable="false" /></a>

        <ul class="list" style={{color: 'white'}}>
          <li><a href="" class="nav-link active">Home</a></li>
          
          <li><Link to="/login"  class="nav-link">Invest</Link></li>
        </ul>

        <i class="fa fa-bars" id="menu-btn"></i>
      </nav>
    </header>

    <i class="fa fa-arrow-up" id="scroll-top"></i>

    <section class="home" id="home">
      <div class="container">
        <div class="text" data-aos="fade-right">
          <h1 style={{color:'white'}}>Invest Smarter With Bluvill Investment Platform</h1>
          <p>
           Free your money and invest with confidence. Explore the best of our plans
          </p>
          <div class="btn-group">
            <Link class="btn" to='/login' style={{color:'white', fontWeight:'bold', fontSize:'large'}}>Get Started</Link>
        
          </div>
        </div>
        <div class="image" data-aos="fade-left">
          <img src="static/assets/img/home.png" alt="Home Image" draggable="false" />
        </div>
      </div>
    </section>

    <section class="about" id="about">
      <div class="container" data-aos="fade-up">
        <div class="wrapper">
          <div class="text">
            <div class="title">
              <h2 style={{color:'white'}}>About Bluvill</h2>
              <br/>
            </div>
            <p>
              Bluvill is a comprehensive platform that enables users to 
              effortlessly make smart and secure investment.
              At Bluvill we see investing differently, we are building a
              platform without digital walls and complex barriers. A platform 
              that enables you to see new possiblities for your money and make them
              a reality

            </p>
            <ul style={{color:'white'}}>
              <li><i class="fa fa-check"></i>Regulated</li>
              <li><i class="fa fa-check"></i>Safe and Secure</li>
              <li><i class="fa fa-check"></i>Trusted</li>
            </ul>

          </div>

          <div class="image">
            <img src="static/assets/img/about.png" alt="About Image" draggable="false" />
          </div>
        </div>
      </div>
    </section>

    <section class="services" id="services">
      <div class="container" style={{color:'white'}}>
        <div class="title">
          <h3>Services</h3>
          <h2>Services We Offer</h2>
        </div>

        <div class="wrapper" >
          <div class="card" data-aos="fade-up" data-aos-delay="100">
            <img src="static/assets/img/services/wallet.png" alt="Wallet" draggable="false" />
            <h4 style={{color:'white'}}>Easy Wallet</h4>
         
          </div>

          <div class="card" data-aos="fade-up" data-aos-delay="200">
            <img src="static/assets/img/services/cloud.png" alt="Cloud" draggable="false" />
            <h4 style={{color:'white'}}>Secure Storage</h4>
          
          </div>

          <div class="card" data-aos="fade-up" data-aos-delay="300">
            <img src="static/assets/img/services/exchange.png" alt="Exchange" draggable="false" />
            <h4 style={{color:'white'}}>Instant Exchange</h4>
          
          </div>

          <div class="card" data-aos="fade-up" data-aos-delay="400">
            <img src="static/assets/img/services/buy&sell.png" alt="Buy&Sell" draggable="false" />
            <h4 style={{color:'white'}}>Safe Investment</h4>
           
          </div>

          <div class="card" data-aos="fade-up" data-aos-delay="500">
            <img src="static/assets/img/services/server.png" alt="Server" draggable="false" />
            <h4 style={{color:'white'}}> Internal Intergration</h4>
          
          </div>

          <div class="card" data-aos="fade-up" data-aos-delay="600">
            <img src="static/assets/img/services/hand.png" alt="Hand" draggable="false" />
            <h4 style={{color:'white'}}>Reliable & Low Cost</h4>
            
          </div>
        </div>
      </div>
    </section>

    
 
    <section class="team" id="team">
      <div class="container">
        <div class="title">
          <h3>Team</h3>
          <h2  style={{color:'white'}}>Our Team</h2>
        </div>

        <div class="wrapper">
          <div class="card" data-aos="fade-up" data-aos-delay="100">
            <div class="image">
              <img src="static/assets/img/team/1.jpeg" alt="Team Member" />

              <div class="overlay">
                <div class="social-icons">
               
                </div>
              </div>
            </div>
            <h4>Michael Curtis</h4>
            <h5>Founder & Ceo</h5>
          </div>

          <div class="card" data-aos="fade-up" data-aos-delay="200">
            <div class="image">
              <img src="static/assets/img/team/2.jpg" alt="Team Member" />

              <div class="overlay">
                <div class="social-icons">
                
                </div>
              </div>
            </div>
            <h4>Daniel Obrien</h4>
            <h5>Account Manager</h5>
          </div>

          <div class="card" data-aos="fade-up" data-aos-delay="300">
            <div class="image">
              <img src="static/assets/img/team/3.jpeg" alt="Team Member" />

              <div class="overlay">
               
              </div>
            </div>
            <h4>Tiffany Henderson</h4>
            <h5>Account Manager</h5>
          </div>

          <div class="card" data-aos="fade-up" data-aos-delay="400">
            <div class="image">
              <img src="static/assets/img/team/4.jpg" alt="Team Member" />

              <div class="overlay">
              
              </div>
            </div>
            <h4>Brian Shaw</h4>
            <h5>Marketing Manager</h5>
          </div>
        </div>
      </div>
    </section>

    
    

  
  
    

    <footer style={{color:'white'}}> 
      <div class="container">
        <div class="wrapper">
          <div class="info">
            <a class="logo" href="#"><img src="static/assets/img/logo.png" alt="Logo" draggable="false" /></a>
        
       
          </div>

          <div class="list">
            <h5>Contact us</h5>
            <br/>
           <span style={{fontWeight:'bold', fontSize:'large'}}>bluvill@email.com</span> 
         
          </div>

          

          <div class="news-letter">
            <h5>Send a message</h5>
            <form onSubmit={sendSubmit}>
              <input type="email" placeholder="Email Address" ref={email_ref} />
              <textarea ref={message_ref} ></textarea>

              <button class="btn" type="submit">send</button>
            </form>
          </div>
        </div>

        <div class="copyright">
          <p>© Bluvill<a href="#"></a></p>
        </div>
      </div>
    </footer>
    </div>
  )
}

export default Landing