import  Modal  from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useState } from "react";
import Deposit from "./Deposit";

import {CopyToClipboard} from "react-copy-to-clipboard"

function Modals(props){



    

    return(
        
        <>
      <Button style={{backgroundColor:'#110451 ', border:'none'}} onClick={props.onshow}>
       Proceed
      </Button>
        <Modal show = {props.show} size="md" backdrop={true} >
         <Modal.Header style={{paddingLeft:'35%', fontWeight:"bold", fontSize:'6mm', color:"white", backgroundColor:'#110451'}}>Deposit BTC</Modal.Header>
        <Deposit/>
        <Modal.Footer style={{border:'none', }}>
        <Button style={{backgroundColor:'#110451 ', border:'none',}} onClick={props.onclose}>Close</Button>
          <Button style={{backgroundColor:'#110451 ', border:'none'}} onClick={props.onconfirm}>Confirm</Button>
        </Modal.Footer>
      </Modal>
      </>



    )




}
export default Modals;