import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import {CDBCard,CDBCardBody,CDBContainer,} from 'cdbreact'
import { Card } from "react-bootstrap";
import { Form,InputGroup,ButtonGroup,Button} from "react-bootstrap";
import Modals from "../components/Modal";
import Deposit from "../components/Deposit";
import { useState,useRef,useContext,useEffect } from "react";
import Modal from "react-bootstrap/Modal"
import { jwtDecode } from "jwt-decode"
import AuthContext from "../context/AuthContext"
import useAxios from "../utils/useAxios"
import './dashboard.css'


function WithdrawScreen () {

  
  const token = localStorage.getItem("authTokens")
  const myref = useRef()
  const address_ref = useRef()
  const withdraw_ref= useRef()
  const swal = require('sweetalert2')
  const api = useAxios();

  if (token){
    const decode = jwtDecode(token)
    var user_id = decode.user_id
    var username = decode.username
    var balance = decode.balance
    
    
  }
  const [show, setShow] = useState(false);
  const [user, setUser] = useState('')
  const [amount, setAmount] = useState()
  const [wallet, setWallet] = useState('')
  const[btc_amount,setBtcamount] = useState('')
  const [user_wallet, setUserWallet] = useState('')

  const {withdraw} = useContext(AuthContext)
  
  useEffect(() => {
    const fetchData = async () => {
      try{
       
        const response = await api.get(`wallet/${user_id}`)
        const data = response.data
        setUserWallet(data)
      } catch (error) {
        console.log(error);
       
        
       
      }
     
    }
    fetchData()
  },[])

  useEffect(()=>{
    const estimation=()=>{
      setBtcamount(amount*0.000016)
      setUser(myref.current.value)
      setWallet(address_ref.current.value)
    }
    estimation()
  })

  const walletBody=[]
  
  for ( let index = 0; index < user_wallet.length;  index++) {
      

   
    let new_table_body = 
      <option value={user_wallet[index].name} style={{fontWeight:"bold"}}>{user_wallet[index].name}</option>
  
     walletBody.splice(0,0,new_table_body) 
    
   }
  

 

  const withdrawSubmit = async e  => {
    e.preventDefault()
    const form = withdraw_ref.current.value
    const address = address_ref.current.value
    if (form>0 && address && balance>=amount){
   
    withdraw( user,wallet,amount)
  }
  else if(!address){
    swal.fire({
      title: "Wallet not detected ",
      icon: "error",
      toast: true,
      timer: 6000,
      position: 'top-right',
      timerProgressBar: false,
      showConfirmButton: false,
  })

  }
  else if(balance<amount){
    swal.fire({
      title: "Balance is too low",
      icon: "error",
      toast: true,
      timer: 6000,
      position: 'top-right',
      timerProgressBar: false,
      showConfirmButton: false,
  })


  }

  else{
    console.log('invalid')
    console.log(amount)
    swal.fire({
        title: "Invalid Amount",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: true,
        showConfirmButton: false,
    })
}
}



    const open=()=> setShow(true)
    const close =()=> setShow(false)


    return(
        <div className="dashboard d-flex">
        <div className="mysidebar">
        <Sidebar/>
        </div>
        <div style={{flex:"1 1 auto", display:"flex", flexFlow:"column", height:"100vh", overflowX:"hidden"}}>
        <div >
        <Navbar  username={username}/>
        </div>
        <CDBContainer style={{padding:'30px', paddingLeft:'40px',}}>

            
            <Card.Header style={{paddingTop:'15px', fontSize:'8mm',paddingLeft:'15px', fontFamily:'Helvetica', fontWeight:'bold',color:'#110451'}}>Withdraw Funds</Card.Header>
            <Card.Title style={{fontSize:'small'}}></Card.Title>
            <br/>
            <Form onSubmit={withdrawSubmit}>
            <Form.Group>
              <Form.Label style={{paddingRight:'80%',fontWeight:'bold', }}> Withdraw Method</Form.Label>
              <Form.Control style={{backgroundColor:"transparent"}} type='text' placeholder='Bitcoin Transfer' readOnly/>
            </Form.Group>
            <br/>

            <Form.Group>
              <Form.Label style={{paddingRight:'80%',fontWeight:'bold', }}> Import Wallet</Form.Label>
              <Form.Select aria-label="Default select example" ref={address_ref}>
              {walletBody}
              </Form.Select>
              <Form.Text style={{fontFamily:"monospace"}}>Make sure you have a valid wallet</Form.Text>
            </Form.Group>
            </Form>
            <br/>

            <Form.Group>
              <Form.Label style={{paddingRight:'80%', fontWeight:'bold', }}> Amount</Form.Label>
              <Form.Control style={{backgroundColor:"transparent"}} type='number' placeholder='0.00' onChange={e=>setAmount(e.target.value)} ref={withdraw_ref} />
            </Form.Group>
            <br/>
            <Form.Group>
              <Form.Label style={{paddingRight:'80%',backgroundColor:"transparent", color:"black"}}> Currency</Form.Label>
              <Form.Select style={{backgroundColor:"transparent"}}>
                <option> USD</option>


              </Form.Select>
              
            </Form.Group>
            <br/>
            <Form.Group>
              <Form.Label style={{ color:"black"}}> Estimation</Form.Label>
              <InputGroup>
              <Form.Control style={{backgroundColor:"transparent"}} type='number' defaultValue={btc_amount}  readOnly />
              <InputGroup.Text>BTC</InputGroup.Text>
              </InputGroup>
              <Form.Text style={{color:"GrayText",fontFamily:'monospace'}}> Transactions will be pending until it has been confirmed</Form.Text>
            </Form.Group>
            <br/>
            <Button style={{backgroundColor:'#110451 ', border:'none', }}  onClick={open}>  Proceed </Button>
            <Modal show={show}>
                <Modal.Header style={{fontWeight:"bold", fontSize:'5mm', backgroundColor:"#110451",color:'white'}}>Withdraw</Modal.Header>
                <Modal.Body>You are about to make a Withdrawal of ${amount}?</Modal.Body>
                <Modal.Footer>
                <Button style={{backgroundColor:'#110451'}} onClick={close} >Close</Button>
               <Button style={{backgroundColor:'#110451'}} onClick={withdrawSubmit} type="submit">Confirm</Button>
                </Modal.Footer>


            </Modal>
            <input type="hidden" id="my_userid" name="username" value={user_id} ref={myref} ></input>
            <Form/>
            
        </CDBContainer>
        </div>
        </div>






    )






}
export default WithdrawScreen;