import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import {CDBCard,CDBCardBody,CDBContainer,} from 'cdbreact'
import { Card } from "react-bootstrap";
import { Form,InputGroup,ButtonGroup,Button} from "react-bootstrap";
import Modals from "../components/Modal";
import Deposit from "../components/Deposit";
import { jwtDecode } from "jwt-decode"
import { useState,useContext , useRef,useEffect} from "react";
import AuthContext from "../context/AuthContext"
import './dashboard.css'
import "../components/Table"
import Table from "../components/Table";



function DepositScreen () {

  const token = localStorage.getItem("authTokens")
  const myref = useRef()
  
  const swal = require('sweetalert2')

  
 

  if (token){
    const decode = jwtDecode(token)
    var user_id = decode.user_id
    var username = decode.username
    var balance = decode.balance
    
  }
  const [show, setShow] = useState(false);
  const [user, setUser] = useState('')
  const [amount, setAmount] = useState('')
  const[btc_amount,setBtcamount] = useState('')

  const {deposit} = useContext(AuthContext)
  const deposit_ref= useRef()
  const amountRef= useRef()
  useEffect(()=>{
    const estimation=()=>{
      setBtcamount(amount*0.000016)
      setUser(myref.current.value)
    }
    estimation()

  })
  
  
  
  ;
  
  console.log(amount);


 

  const depositSubmit = async e  => {
    e.preventDefault()
    const form = amountRef.current.value;
    if (form>0 ){
    deposit( user,amount)
  }
  
  

  
  else{
    console.log('invalid')
    swal.fire({
        title: "Invalid Amount",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: false,
        showConfirmButton: false,
    })
}


   

}

  const handle =()=> setShow(true);
  const close =()=> setShow(false)


    return(
        <div className="dashboard d-flex">
        <div className="mysidebar">
        <Sidebar/>
        </div>
        <div style={{flex:"1 1 auto", display:"flex", flexFlow:"column", height:"100vh", overflowX:"hidden"}}>
        <div>
        <Navbar  username={username}/>
        </div>
        <CDBContainer style={{padding:'30px', paddingLeft:'40px', fontWeight:'bold', color:"black", backgroundColor:"whitesmoke",height:"100%"}} >
            <Card.Header style={{paddingTop:'15px', fontSize:'8mm',paddingLeft:'15px', fontFamily:'Helvetica',color:'#110451'}}>Deposit Funds</Card.Header>
            <br/>
            <Form onSubmit={depositSubmit}>
            <Form.Group style={{fontWeight:'bold', color:"black", backgroundColor:"transparent"}}>
              <Form.Label style={{paddingRight:'80%',fontWeight:'bold', color:"black", backgroundColor:"transparent"}}> Payment Method</Form.Label>
              <Form.Control  style={{backgroundColor:"transparent", }} type='text' value = 'BTC TRANSFER' readOnly  />
            </Form.Group>
            <br/>

            <Form.Group>
              <Form.Label style={{paddingRight:'80%', color:"black"}}> Amount</Form.Label>
              <Form.Control style={{backgroundColor:"transparent", }} type='number'name="amount" placeholder='0.00'  onChange={e=>setAmount(e.target.value)}  ref={amountRef} />
            </Form.Group>
            <br/>


           
            <Form.Group>
              <Form.Label style={{paddingRight:'80%',backgroundColor:"transparent", color:"black"}}> Currency</Form.Label>
              <Form.Select style={{backgroundColor:"transparent"}}>
                <option> USD</option>


              </Form.Select>
              
            </Form.Group>
            <br/>


            
            <Form.Group>
              <Form.Label style={{ color:"black"}}> Estimation</Form.Label>
              <InputGroup>
              <Form.Control style={{backgroundColor:"transparent"}} type='number' defaultValue={btc_amount}  readOnly />
              <InputGroup.Text>BTC</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Text style={{color:"GrayText",fontFamily:'monospace'}}>Transactions will be pending until it has been confirmed</Form.Text>
            <div style={{paddingTop:'35px',paddingLeft:'10px', float:'right', }}>
      
            <Modals show={show}  onshow={handle} onclose={close} onconfirm={depositSubmit} />
            </div>
            <input type="hidden" id="my_userid" name="username" value={user_id} ref={myref} ></input>
            </Form>

        </CDBContainer>
        </div>
        </div>






    )






}
export default DepositScreen;