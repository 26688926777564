import { Card, Container,Row,Col,Button } from "react-bootstrap";
import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import Image from 'react-bootstrap/Image';
import logo from "../assets/a-sm.jpg"
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { jwtDecode } from "jwt-decode"
import useAxios from "../utils/useAxios"
import { useState,useEffect } from "react";
import './dashboard.css'

function InvestLog(){

  const [res, setRes] = useState("")
  const [table_row, settable_row] = useState("[]")

    const table_head=["#","Plan", "Amount", "Status","Investment-Date","Payment-Date"]
    const table_body = [

                      
    ]
    const api = useAxios();
    const token = localStorage.getItem("authTokens")
    if (token){
      const decode = jwtDecode(token)
      var user_id = decode.user_id
      var username = decode.username
      var balance = decode.balance
    }
    

    useEffect(() => {
      const fetchData = async () => {
        try{
          const response = await api.get(`invest_log/${user_id}`)
          setRes(response.data.response)
          settable_row(response.data)
          
         

        
          
        } catch (error) {
          console.log(error);
          setRes("Something went wrong")
          
         
        }
       
      }
      fetchData()
    }, [])

    
    


    let number = 0
   

   
 for ( let index = 0; index < table_row.length; index++) {
      

  
   
  let new_table_body = <tr>
    <td  style={{fontWeight:"bold"}}>{table_row.length-index}</td>
    <td  style={{fontWeight:"bold"}}>{table_row[index].Plan}</td>
    <td  style={{fontWeight:"bold"}}>${table_row[index].amount}.00</td>
    <td  style={{fontWeight:"bold"}}>{table_row[index].status}</td>
    <td style={{fontWeight:"bold"}}>{table_row[index].invest_date}</td>
    <td  style={{fontWeight:"bold"}}>{table_row[index].payment_date}</td>
    </tr>
  table_body.splice(0,0,new_table_body)
    
     
    
  
 }
    


    return(
        <div className="dashboard d-flex">
        <div className="mysidebar">
        <Sidebar/>
        </div>
        <div style={{flex:"1 1 auto", display:"flex", flexFlow:"column", height:"100vh", overflowX:"hidden"}}>
        <div>
        <Navbar username={username}/>
        </div>
        <Container style={{backgroundColor:'transparent', height:'100%'}} fluid>
            <Card style={{marginTop:'3%', }}>
                <Card.Header style={{fontSize:'large', paddingBottom:'20px',fontWeight:"bold",color:'#110451'}}>Investment History <br/> <span style={{fontSize:'small', color:'GrayText'}}>See history of your Investments</span> </Card.Header>
                <Card.Text ></Card.Text>
            <Table responsive="sm" >
      <thead style={{paddingTop:'30px'}}>
        <tr>
      {table_head.map((head,headID)=>(<th className="table-head-font" style={{backgroundColor:'#110451',color:'white'}} key={headID}>{head}</th>))}
      </tr>
      </thead>
      <tbody style={{fontSize:'small'}}>
       {table_body}
      </tbody>
    </Table>
            </Card>
        </Container>
        
  
        </div>
        </div>)
}

export default InvestLog 