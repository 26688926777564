import {useState, useContext,useRef} from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import { Image,Button,Card,Row,Col} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import logo from '../assets/logo.png'
import './register.css'


function Registerpage() {

  const swal = require('sweetalert2')
  const [first_name, setFirst_Name] = useState("")
  const [last_name, setLast_Name] = useState("")
  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [phone_number, setPhone_Number] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")

  const {registerUser} = useContext(AuthContext)

  console.log(email);
  console.log(username);
  console.log(password);
  console.log(password2);


  const handleSubmit = async e => {
    e.preventDefault()
    if (password === password2 && password.length>=8){
    registerUser(first_name,last_name,email,username,phone_number,password,password2)
    }

    else if(password.length<8){
      swal.fire({
        title: "Password should be at least eight characters",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: false,
        showConfirmButton: false,
    })


    }
    else{
      swal.fire({
        title: "Password does not match",
        icon: "error",
        toast: true,
        timer: 6000,
        position: 'top-right',
        timerProgressBar: false,
        showConfirmButton: false,
    })
}
 
    
  }
  

  return (
    <div>
      <>
        <section   style={{ backgroundColor: "#110451", }}>
        <div style={{justifySelf:'center'}}> <Image src={logo}/>
        <br/>
        <span style={{color:'white',padding:'40px', fontWeight:'bold',fontSize:'large'}}>SIGN UP </span>
  
        </div>
      
      <Col>
      <Card className='regcard' >
      
       
        <Card.Body>
         
          <Form onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>First Name</Form.Label>
              <Form.Control type='text' onChange={e=>(setFirst_Name(e.target.value))}/>
            </Form.Group>
            <br/>
            <Form.Group as={Col}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type='text' onChange={e=>(setLast_Name(e.target.value))}/>
            </Form.Group>
            </Row>
            <br/>
            <Row>
            <Form.Group as={Col}>
              <Form.Label>User Name</Form.Label>
              <Form.Control type='text' onChange={e=>(setUsername(e.target.value))}/>
            </Form.Group>
            <br/>

            <Form.Group as={Col}>
              <Form.Label>Email</Form.Label>
              <Form.Control type='Email'   name='Email' onChange={e=>setEmail(e.target.value)} />

            </Form.Group>
            </Row>
             <br/>
             <Row>
                <Form.Group as={Col}>
              <Form.Label>Password</Form.Label>
              <Form.Control type='password' name='password' onChange={e=>(setPassword(e.target.value))}/>
            </Form.Group>
            <br/>
            <Form.Group as={Col}>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type='password'  name='password'onChange={e=>(setPassword2(e.target.value))} />

            </Form.Group>
            <span style={{color:'gray', fontSize:'small', fontFamily:'monospace'}}>Password should have at least 8 characters</span>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type='integer'  name='phone_number' onChange={e=>(setPhone_Number(e.target.value))} />

            </Form.Group>
            </Row>
            <br/>
            <Button style={{width:'100%', backgroundColor:'#110451'}} type='submit'>Register</Button>
          </Form>

        </Card.Body>
        <Card.Footer>
        <Link to="/login" style={{color:'#110451',textDecoration:'underline'}}>Already a Member? Login</Link>


        </Card.Footer>
        

      </Card>
      </Col>
          
        </section>
        
    </>

    </div>
  )
}

export default Registerpage